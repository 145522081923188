<template>
  <div class="content-introduce container">
    <client-only>
      <h4 class="font-weight-bold text-center mt-3" style="font-size: 18px;">ĐIỀU KHOẢN SỬ DỤNG</h4>
      <div class="description text-justify text-break">
        <span class="font-weight-bold">Công ty Cổ phần Công nghệ và Dịch vụ viễn thông Mặt trời (Suntech Media) </span>
        Dưới đây là những điều khoản được áp dụng cho học viên và đối tác của Vilearn. Xin hãy đọc kỹ toàn bộ thỏa thuận trước khi tham gia.
        Một khi bạn đã đăng ký tham gia trên <a href="https://vilearn.vn/" style="color: #1D54CF">Vilearn.vn</a> (bao gồm việc đăng ký là Affiliate, Affiliate Manager, Giảng viên), chúng tôi sẽ hiểu rằng bạn đã đọc và đồng ý toàn bộ điều khoản được đưa ra trong bản thỏa thuận này.
        Bản cập nhật mới nhất (nếu có) sẽ được đăng tại tại đây và Vilearn sẽ không thông báo đến từng đối tác, vì vậy bạn hãy quay lại trang này thường xuyên để cập nhật chính sách mới nhất.
      </div>
      <div class="text-justify text-break pt-3">
        ĐIỀU KHOẢN CHUNG
      </div>
      <div class="text-justify text-break font-weight-bold pt-1">
        Điều 1: Thông tin tài khoản cá nhân
      </div>
      <div class="text-justify text-break pl-5">
        <ul class="mb-0">
          <li>Khi đăng ký tài khoản Vilearn, để được hỗ trợ nhanh chóng, bạn cần cung cấp đầy đủ và chính xác các thông tin: Họ tên, Email, Phone, Giới tính, Ngày sinh, Thành phố,..</li>
          <li>Chúng tôi sử dụng thông tin liên lạc của bạn để gửi mã kích hoạt khóa học, thông báo chương trình khuyến mãi, xác nhận đổi mật khẩu, các thảo luận trong lớp học,..</li>
          <li>Thông tin ngày sinh và giới tính dùng để gợi ý đến bạn những khóa học phù hợp, cũng như gửi quà tặng đến bạn trong ngày sinh nhật.</li>
          <li>Bạn có thể đăng nhập bằng tài khoản Vilearn (email + mật khẩu) hoặc đăng nhập bằng Google, Facebook.</li>
          <li>Bạn có thể cập nhật thông tin cá nhân hoặc hủy (xóa) tài khoản bất kỳ lúc nào khi không còn nhu cầu sử dụng</li>
        </ul>
      </div>
      <div class="text-justify text-break font-weight-bold pt-2">
        Điều 2: Việc bảo mật thông tin
      </div>
      <div class="text-justify text-break pl-5">
        <ul class="mb-0">
          <li>Bạn có trách nhiệm tự mình bảo quản mật khẩu, nếu mật khẩu bị lộ ra ngoài dưới bất kỳ hình thức nào, Vilearn sẽ không chịu trách nhiệm về mọi tổn thất phát sinh.</li>
          <li>Mọi thông tin cá nhân của bạn sẽ được chúng tôi bảo mật, không tiết lộ ra ngoài. Chúng tôi không bán hay trao đổi những thông tin này với bất kỳ một bên thứ ba nào khác. Tuy nhiên, trong trường hợp cơ quan chức năng yêu cầu, Vilearn buộc phải cung cấp những thông tin này theo quy định pháp luật.</li>
          <li>Bạn có quyền sở hữu trọn đời các khóa học đã đăng ký: không giới hạn số lần tham gia học và thời gian học.</li>
          <li>Bạn không được download video, không được chia sẻ video lên Internet với bất kỳ hình thức nào. Nếu vi phạm, tài khoản của bạn sẽ bị khoá và bạn phải chịu trách nhiệm trước pháp luật về hành vi xâm phạm sở hữu trí tuệ.</li>
          <li>Vilearn có thể gửi thông báo tình hình học tập, chương trình khuyến mãi (nếu có), thông báo khóa học mới sắp ra mắt để học viên quan tâm có thể đăng ký ngay để được ưu đãi. Nếu bạn không muốn nhận email có thể bấm vào link "Ngừng nhận email" ở cuối email.</li>
        </ul>
      </div>

      <div class="text-justify text-break font-weight-bold pt-2">
        Điều 3: Đánh giá khóa học và thảo luận
      </div>
      <div class="text-justify text-break pl-5">
        <ul class="mb-0">
          <li>Học viên khi tham gia khóa học trên Vilearn có quyền đánh giá về chất lượng khóa học.</li>
          <li>Trong quá trình học, học viên có bất kỳ thắc mắc hay góp ý nào có thể đăng bình luận của mình lên phần Thảo luận - ngay trong giao diện bài học để được chuyên viên Vilearn và Giảng viên hỗ trợ giải đáp.</li>
          <li>Bên cạnh đó, mỗi khóa học trên Vilearn đều có 1 Group Thảo luận riêng cho các học viên và giảng viên để trao đổi các vấn đề chuyên môn.</li>
        </ul>
      </div>
      <div class="text-justify text-break font-weight-bold pt-2">
        Điều 4: Nghiêm cấm sử dụng dịch vụ với các hành vi dưới đây
      </div>
      <div class="text-justify text-break pl-5">
        <ul class="mb-0">
          <li>Sử dụng bất kỳ công cụ hay hình thức nào để can thiệp vào các dịch vụ, khóa học trong hệ thống Vilearn.</li>
          <li>Phát tán hoặc tuyên truyền cổ vũ các hoạt động phát tán, can thiệp và phá hoại nội dung các bài học trên hệ thống của Vilearn ra bên ngoài. Mọi vi phạm khi bị phát hiện sẽ bị xóa tài khoản và có thể xử lý theo quy định của pháp luật về việc vi phạm bản quyền.</li>
          <li>Sử dụng chung tài khoản: với việc trên 2 người cùng sử dụng chung một tài khoản khi bị phát hiện sẽ bị xóa tài khoản ngay lập tức.</li>
          <li>Xúc phạm, nhạo báng người khác dưới bất kỳ hình thức nào: chê bai, kỳ thị tôn giáo, giới tính, sắc tộc..</li>
          <li>Hành vi mạo nhận hay cố ý làm người khác tưởng lầm mình là một người sử dụng khác trong hệ thống dịch vụ của Vilearn.</li>
          <li>Bàn luận về các vấn đề chính trị, kỳ thị tôn giáo, kỳ thị sắc tộc.</li>
          <li>Hành vi, thái độ làm tổn hại đến uy tín của các sản phẩm, dịch vụ, khóa học trong hệ thống Vilearn dưới bất kỳ hình thức nào, phương thức nào.</li>
          <li>Mua bán chuyển nhượng tài khoản, khóa học của Vilearn. (chỉ được phân phối khóa học qua hình thức Affiliate)</li>
          <li>Mạo danh Vilearn ảnh hưởng đến uy tín của Vilearn, gây sự nhầm lẫn cho các học viên và đối tác theo bất kỳ phương thức nào (dùng địa chỉ email, tên miền website, fanpage có chữ Vilearn..)</li>
          <li>Khi phát hiện những hành vi trên từ tài khoản của bạn, Vilearn có quyền tước bỏ mọi quyền lợi liên quan đối với tài khoản (bao gồm việc khóa tài khoản) hoặc sử dụng những thông tin mà bạn cung cấp khi đăng ký tài khoản để chuyển cho cơ quan chức năng giải quyết theo quy định của pháp luật.</li>
        </ul>
      </div>
      <div class="text-justify text-break font-weight-bold pt-2">
        Điều 5: Trường hợp thanh toán tiền thừa
      </div>
      <div class="text-justify text-break pl-5">
        <ul class="mb-0">
          <li>Tiền thanh toán thừa cho khóa học được chuyển vào ví điện tử trong tài khoản Vilearn của khách hàng để thanh toán cho các đơn hàng tiếp theo.</li>
          <li>Trường hợp thanh toán thừa do lỗi hệ thống của Vilearn, chúng tôi sẽ trả lại tiền thừa hoặc chuyển sang ví điện tử Vilearn để thanh toán vào đơn hàng tiếp theo (tuỳ theo nguyện vọng của bạn).</li>
        </ul>
      </div>
      <div class="text-justify text-break font-weight-bold pt-2">
        Điều 6: Chính sách hoàn trả học phí
      </div>
      <div class="text-justify text-break">
        Học viện Online Vilearn luôn mong muốn tạo điều kiện thuận lợi nhất cho bạn để học hỏi và cập nhật những kiến thức hữu ích cho công việc cũng như đời sống thông qua các khóa học chất lượng trên Vilearn.
        Tuy nhiên, nếu bạn không hài lòng hoặc nội dung khóa học không như bạn mong đợi, bạn có thể yêu cầu hoàn lại học phí qua email: cskh@Vilearn.vn
      </div>
      <div class="text-justify text-break pl-5">
        <ol>
          <li>Trong vòng 7 ngày kể từ ngày thanh toán</li>
          <li>Học chưa quá 30% số bài học.</li>
          <li>Mua khóa học và thanh toán trực tiếp qua Vilearn, hoặc đại lý được cấp quyền phân phối khoá học bởi Vilearn.</li>
        </ol>
      </div>
      <div class="text-justify text-break">
        Trường hợp không thanh toán trực tiếp qua Vilearn (mua từ Giảng viên, từ 1 tài khoản khác...), Vilearn không thể hoàn học phí.
      </div>
      <div class="text-justify text-break">
        Nội dung email đề nghị hoàn học phí:
      </div>
      <div class="text-justify text-break pl-5">
        <ul>
          <li>Tiêu đề email: Yêu cầu hoàn học phí</li>
          <li>Nội dung email, bạn cần ghi rõ:</li>
          <ul class="mb-0 pl-5">
            <li>Họ và tên:</li>
            <li>Email đăng ký học:</li>
            <li>Tên khóa học:</li>
            <li>Số tiền học phí đã nộp:</li>
            <li>Cách thức đã nộp học phí: Chuyển khoản/ ATM Online/ Thu tiền tại nhà.</li>
            <li>Cách thức bạn muốn nhận lại tiền hoàn học phí:</li>
          </ul>
          <li>Số tiền hoàn = Số tiền thực nhận về Vilearn (sau khi trừ đi phí tại cổng thanh toán)</li>
          <li>Thời hạn hoàn tiền: trong vòng 7 ngày làm việc kể từ ngày nhận được yêu cầu của bạn.</li>
        </ul>
      </div>
      <div class="text-justify text-break font-weight-bold pt-3">
        Điều 7: Chính sách đổi trả đối với thẻ học Vilearn
      </div>
      <div class="text-justify text-break">
        Nhằm đảm bảo chất lượng cũng như hiệu quả học tập của học viên, Vilearn có chính sách đổi trả đối với sản phẩm thẻ học như sau:<br>
        Chính sách hoàn trả học phí và đổi thẻ học:<br>
        Đối với các thẻ đã giao nhưng học viên chưa học, thẻ sẽ được đổi trả nếu:<br>
      </div>
      <div class="text-justify text-break pl-5">
        <ul>
          <li>Trong vòng 90 ngày kể từ ngày thanh toán</li>
          <li>Thẻ bị lỗi kỹ thuật, hư hỏng không sử dụng và kích hoạt được.</li>
          <li>Giao sai loại thẻ.</li>
          <li>Thẻ học được  thanh toán trực tiếp qua Vilearn, hoặc đại lý được cấp quyền phân phối khoá học bởi Vilearn.</li>
        </ul>
      </div>
      <div class="text-justify text-break">
        Đối với các thẻ đã giao và học viên đã vào học, thẻ được đổi trả nếu:
      </div>
      <div class="text-justify text-break pl-5">
        <ul>
          <li>Trong vòng 7 ngày kể từ ngày thanh toán</li>
          <li>Học chưa quá 30% số bài học. </li>
        </ul>
      </div>
      <div class="text-justify text-break">
      Quy định về thời gian thông báo và gửi sản phẩm đổi trả<br>
      Thời gian thông báo đổi trả: trong vòng 24h kể từ khi nhận sản phẩm đối với trường hợp Thẻ sai, thẻ lỗi. Thời gian gửi đổi thẻ mới: trong vòng 14 ngày từ ngày nhận thẻ.<br>
      Số tiền hoàn trả = Số tiền thực nhận về Vilearn (sau khi trừ đi phí tại cổng thanh toán, chi phí vận chuyển). Cách thức yêu cầu đổi trả thẻ học: gửi email tới <span style="color: #3D81FF">cskh@Vilearn.vn.</span><br>
      </div>
    </client-only>
    </div>
</template>
<script>
export default {
  name: 'DieuKhoanSuDung'
}
</script>

<style scoped>
.content-introduce{
  padding: 15px;
}
</style>
