<template>
  <div class="content-introduce container">
    <client-only>
      <!-- #######  HEY, I AM THE SOURCE EDITOR! #########-->
      <h4 class="font-weight-bold text-center mt-3" style="font-size: 18px;">CHÍNH SÁCH BẢO VỆ DỮ LIỆU CÁ NHÂN</h4>

      <p>Chính sách bảo vệ dữ liệu cá nhân đối với Khách hàng của Tập đoàn Bưu chính Viễn thông Việt Nam, các Công ty con của Tập đoàn Bưu chính Viễn thông Việt Nam (sau đây gọi tắt là <b>“Chính sách”</b>) nhằm mục đích thông báo với Khách hàng những Dữ liệu cá nhân của Khách hàng do Tập đoàn Bưu
        chính
        Viễn
        thông Việt Nam (<b>“VNPT”</b>) và/hoặc do các Công ty con có 100% vốn điều lệ của VNPT (<b>“Công ty con của VNPT”</b>) xử lý, mục đích xử lý, cách thức xử lý, thời gian lưu trữ, quyền, nghĩa vụ của Khách hàng đối với Dữ liệu cá nhân của mình theo quy định của pháp luật Việt Nam về bảo vệ Dữ
        liệu
        cá nhân v.v.
        Chính sách này đồng thời đưa ra các khuyến nghị để giúp Khách hàng nâng cao nhận thức về bảo vệ Dữ liệu cá nhân.</p>
      <p>Chính sách này là một phần không thể tách rời của các bản Hợp đồng, Điều kiện giao dịch chung, Điều khoản và Điều kiện sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT. Chính sách này được áp dụng cho toàn bộ các nền tảng bao gồm nhưng không giới hạn bởi: trang thông tin điện tử,
        kênh giao
        diện, phương tiện, công cụ trên các website/wapsite/ứng dụng của VNPT/Công ty con của VNPT, việc tư vấn và ký kết hợp đồng cung cấp dịch vụ tại các điểm cung cấp dịch vụ viễn thông của VNPT/Công ty con của VNPT, tại địa chỉ do Khách hàng chỉ định và/hoặc tại một địa điểm khác do Khách hàng
        hoặc
        VNPT/Công ty con của VNPT lựa chọn.</p>
      <p>Trong phạm vi pháp luật cho phép, VNPT/Công ty con của VNPT có thể điều chỉnh Chính sách này vào bất kỳ thời điểm nào đồng thời đăng tải công khai Chính sách được điều chỉnh trên các kênh thông tin chính thống của VNPT/Công ty con của VNPT.</p>
      <p>Bằng việc tích vào ô <i>"Tôi đã đọc và chấp thuận"</i> hoặc <i>"Tôi đồng ý với Chính sách, Điều khoản sử dụng của VNPT/Công ty con của VNPT”</i> hoặc bằng việc ký kết hợp đồng với VNPT/Công ty con của VNPT có dẫn chiếu tới Chính sách này (và các bản sửa đổi kèm theo), hoặc bằng việc tiếp
        tục đăng
        ký, đăng
        nhập, sử dụng website/wapsite/ứng dụng của VNPT/Công ty con của VNPT hoặc sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT mà không có bất kì khiếu nại nào đối với Chính sách này (và các bản sửa đổi kèm theo), Khách hàng xác nhận rằng đã đọc kỹ, hiểu rõ và chấp thuận toàn bộ nội dung
        Chính sách bảo vệ Dữ liệu cá nhân của VNPT.</p>
      <p><b>Điều 1. Giải thích từ ngữ và các từ viết tắt</b></p>
      <p>Trong phạm vi Chính sách này, các thuật ngữ dưới đây được hiểu và giải thích như sau:</p>
      <p>1.1. <b>VNPT</b> là Tập Đoàn Bưu Chính Viễn Thông Việt Nam và các đơn vị trực thuộc Tập Đoàn Bưu Chính Viễn Thông Việt Nam.</p>
      <p>1.2. <b>Công ty con của VNPT</b> là các doanh nghiệp do VNPT sở hữu 100% vốn điều lệ. Công ty con của VNPT bao gồm Tổng công ty Dịch vụ Viễn thông (VNPT VinaPhone) và Tổng công ty Truyền thông (VNPT Media).</p>
      <p>1.3. <b>Khách hàng</b> là:</p>
      <p>- Cá nhân hoặc người đại diện hợp pháp của cá nhân sử dụng và/hoặc quan tâm tới các sản phẩm, dịch vụ của VNPT/Công ty con của VNPT;</p>
      <p>- Cá nhân hoặc người đại diện hợp pháp của cá nhân đã truy cập và/hoặc đăng ký tài khoản tại các website/wapsite/ứng dụng thuộc quyền sở hữu của VNPT/Công ty con của VNPT.</p>
      <p>1.4. <b>Sản phẩm, dịch vụ của VNPT/Công ty con của VNPT</b> là:</p>
      <p>- Sản phẩm, dịch vụ do VNPT hoặc các Công ty con của VNPT trực tiếp nghiên cứu, phát triển và cung cấp cho Khách hàng;</p>
      <p>- Sản phẩm, dịch vụ do VNPT/Công ty con của VNPT hợp tác với đối tác để cung cấp cho Khách hàng.</p>
      <p><b>Điều 2. Xử lý Dữ liệu cá nhân</b></p>
      <p>1. VNPT/Công ty con của VNPT tiến hành xử lý Dữ liệu cá nhân trong những trường hợp dưới đây bao gồm nhưng không giới hạn bởi:</p>
      <ul>
        <p>a) Khi Khách hàng hoặc người đại diện hợp pháp của Khách hàng liên hệ với VNPT/Công ty con của VNPT để yêu cầu tư vấn sản phẩm, dịch vụ của VNPT/Công ty con của VNPT hoặc bày tỏ sự quan tâm tới các sản phẩm, dịch vụ của VNPT/Công ty con của VNPT;</p>
        <p>b) Khi Khách hàng ký kết hợp đồng, đăng ký, sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT;</p>
        <p>c) Khi Khách hàng truy cập và/hoặc đăng ký tài khoản tại các website/wapsite/ứng dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT;</p>
        <p>d) Khi Khách hàng đồng thuận cung cấp Dữ liệu cá nhân cho VNPT/Công ty con của VNPT qua các nguồn công khai, bao gồm nhưng không giới hạn: website/wapsite/ứng dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT; cuộc họp, sự kiện, hội thảo, hội nghị, các mạng xã hội, hay chương trình đối
          thoại,
          thảo luận do VNPT/Công ty con của VNPT tổ chức, tài trợ hoặc tham dự và/hoặc từ các tệp lưu trữ (cookies) ghi nhận được trên website của VNPT/Công ty con của VNPT;</p>
        <p>e) Khi khách hàng của một tổ chức, doanh nghiệp cho phép tổ chức, doanh nghiệp đó chia sẻ dữ liệu cá nhân của khách hàng với VNPT/Công ty con của VNPT;</p>
        <p>g) Là khách hàng của một tổ chức, doanh nghiệp được VNPT/Công ty con của VNPT thực hiện góp vốn, mua cổ phần; hoặc là khách hàng của một tổ chức, doanh nghiệp có hoạt động hợp tác cung cấp sản phẩm, dịch vụ với VNPT/Công ty con của VNPT.</p>
        <p>h) Khi có yêu cầu của các cơ quan nhà nước có thẩm quyền.</p>
        <p>i) Khi VNPT/Công ty con của VNPT tiến hành các công việc theo mục đích xử lý Dữ liệu cá nhân được quy định tại Điều 3 Chính sách này.</p>
      </ul>
      <p>2. Dữ liệu cá nhân của Khách hàng được VNPT/Công ty con của VNPT tiến hành xử lý (sau đây gọi tắt là <b>“Dữ liệu cá nhân”</b>) bao gồm nhưng không giới hạn những thông tin dưới đây và có thể thay đổi tùy thuộc vào loại sản phẩm hoặc dịch vụ, cách thức tương tác của Khách hàng với VNPT/Công
        ty con
        của
        VNPT:</p>
      <p>2.1. Dữ liệu cá nhân cơ bản</p>
      <ul>
        <p>a) Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có);</p>
        <p>b) Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất tích;</p>
        <p>c) Giới tính;</p>
        <p>d) Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú, nơi ở hiện tại, quê quán, địa chỉ liên hệ;</p>
        <p>đ) Quốc tịch;</p>
        <p>e) Hình ảnh của cá nhân;</p>
        <p>g) Số điện thoại, số chứng minh nhân dân, số định danh cá nhân, số hộ chiếu, số giấy phép lái xe, số biển số xe, số mã số thuế cá nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế;</p>
        <p>h) Tình trạng hôn nhân;</p>
        <p>i) Thông tin về mối quan hệ gia đình (cha mẹ, con cái);</p>
        <p>k) Thông tin về tài khoản số của cá nhân; dữ liệu cá nhân phản ánh hoạt động, lịch sử hoạt động trên không gian mạng;</p>
      </ul>
      <p>2.2. Dữ liệu cá nhân nhạy cảm</p>
      <ul>
        <p>a) Dữ liệu về tội phạm, hành vi phạm tội được thu thập, lưu trữ bởi các cơ quan thực thi pháp luật;</p>
        <p>b) Thông tin Khách hàng của tổ chức tín dụng, chi nhánh ngân hàng nước ngoài, tổ chức cung ứng dịch vụ trung gian thanh toán, các tổ chức được phép khác, gồm: thông tin định danh Khách hàng theo quy định của pháp luật, thông tin về tài khoản, thông tin về tiền gửi, thông tin về tài sản
          gửi,
          thông
          tin về giao dịch, thông tin về tổ chức, cá nhân là bên bảo đảm tại tổ chức tín dụng, chi nhánh ngân hàng, tổ chức cung ứng dịch vụ trung gian thanh toán;</p>
        <p>c) Dữ liệu về vị trí của cá nhân được xác định qua dịch vụ định vị.</p>
      </ul>
      <p>2.3. Dữ liệu cá nhân khác không thuộc nhóm Dữ liệu cá nhân cơ bản và Dữ liệu cá nhân nhạy cảm quy định tại khoản 2.1 và khoản 2.2 Điều này.</p>
      <ul>
        <p>a) Dữ liệu cá nhân phái sinh: Điểm tín nhiệm viễn thông được thể hiện dưới dạng điểm số, là thông tin đánh giá xếp hạng từ việc tổng hợp và phân tích dữ liệu sử dụng dịch vụ viễn thông của Khách hàng nhằm đánh giá, phân tích, dự đoán thói quen, sở thích, mức độ tin cậy, tín nhiệm, hành
          vi, xu
          hướng … và các thông tin khác của Khách hàng, hỗ trợ cho việc cung cấp các cung cấp sản phẩm, dịch vụ của VNPT/Công ty con của VNPT một cách phù hợp và tốt nhất.</p>
        <p>b) Các Dữ liệu cá nhân khác phát sinh trong quá trình VNPT/Công ty con của VNPT cung cấp sản phẩm, dịch vụ và trong quá trình Khách hàng sử dụng sản phẩm, dịch vụ, tương tác với nhà cung cấp sản phẩm, dịch vụ.</p>
      </ul>
      <p>2.4. VNPT/Công ty con của VNPT sẽ thông báo cho Khách hàng các Dữ liệu cá nhân bắt buộc phải cung cấp và/hoặc tùy chọn cung cấp tại thời điểm Khách hàng liên hệ, trao đổi hoặc đăng ký, ký kết hợp đồng với VNPT/Công ty con của VNPT. Nếu các Dữ liệu cá nhân bắt buộc không được cung cấp theo
        yêu
        cầu, Khách hàng sẽ không thể sử dụng một số sản phẩm, dịch vụ của VNPT/Công ty con của VNPT. Trong trường hợp này, VNPT/Công ty con của VNPT có thể từ chối cung cấp sản phẩm, dịch vụ cho Khách hàng mà không phải chịu bất kì một khoản bồi thường và/hoặc phạt vi phạm nào.</p>
      <p>2.5. Tại từng thời điểm, Khách hàng có thể tự nguyện cung cấp cho VNPT/Công ty con của VNPT các Dữ liệu cá nhân nằm ngoài yêu cầu của VNPT/Công ty con của VNPT. Khi Khách hàng cung cấp Dữ liệu cá nhân nằm ngoài yêu cầu của VNPT/Công ty con của VNPT đồng nghĩa với việc Khách hàng cho phép
        VNPT/Công ty con của VNPT xử lý Dữ liệu cá nhân của Khách hàng với Mục Đích được nêu trong Chính sách này hoặc với mục đích được nêu tại thời điểm Khách hàng cung cấp những Dữ liệu cá nhân đó. Ngoài ra, khi Khách hàng chủ động cung cấp thông tin nằm ngoài yêu cầu của VNPT/Công ty con của
        VNPT,
        Khách hàng vui lòng không cung cấp Dữ liệu cá nhân nhạy cảm theo quy định của pháp luật tại từng thời điểm. VNPT/Công ty con của VNPT sẽ không thực hiện xử lý và không chịu bất kì trách nhiệm pháp lý nào đối với các Dữ liệu cá nhân nhạy cảm do Khách hàng tự nguyện cung cấp nằm ngoài yêu cầu
        của
        VNPT/Công ty con của VNPT.</p>
      <p><b>Điều 3. Mục đích xử lý Dữ liệu cá nhân</b></p>
      <p>VNPT/Công ty con của VNPT có thể xử lý Dữ liệu cá nhân của Khách hàng cho một hoặc một số mục đích được liệt kê sau đây (<b>“Mục Đích”</b>):</p>
      <p>1. Xác minh tính chính xác, đầy đủ của các thông tin được Khách hàng cung cấp; xác định hoặc xác thực danh tính của Khách hàng và thực hiện quy trình xác thực Khách hàng; Xử lý việc đăng ký sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT;</p>
      <p>2. Thẩm định hồ sơ và khả năng đủ điều kiện của Khách hàng đối với việc sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT. VNPT/Công ty con của VNPT có thể sử dụng các phương pháp chấm điểm, gán ngưỡng cước nóng, kiểm tra lịch sử Khách hàng sử dụng sản phẩm, dịch vụ của VNPT/Công ty
        con của
        VNPT để đánh giá và quản trị rủi ro tín dụng, đảm bảo khả năng thanh toán đối với các nghĩa vụ thanh toán và các nghĩa vụ khác có liên quan trong suốt quá trình cung cấp sản phẩm, dịch vụ của VNPT/Công ty con của VNPT cho Khách hàng;</p>
      <p>3. Quản lý và đánh giá các hoạt động kinh doanh bao gồm thiết kế, cải tiến và nâng cao chất lượng các sản phẩm, dịch vụ của VNPT/Công ty con của VNPT hoặc thực hiện các hoạt động truyền thông tiếp thị; Thực hiện nghiên cứu thị trường, khảo sát và phân tích dữ liệu liên quan đến sản phẩm,
        dịch vụ
        của VNPT/Công ty con của VNPT; nghiên cứu, phát triển các sản phẩm, dịch vụ mới, mô hình cung cấp mới v.v. đáp ứng nhu cầu của Khách hàng;</p>
      <p>4. Cung cấp dịch vụ cho Khách hàng, liên hệ với Khách hàng nhằm tư vấn, trao đổi thông tin, giải quyết yêu cầu, khiếu nại, giao các hóa đơn, các sao kê, các báo cáo hoặc các tài liệu khác liên quan tới sản phẩm, dịch vụ của VNPT/Công ty con của VNPT thông qua các kênh khác nhau (ví dụ:
        email,
        chat) và để trả lời yêu cầu của Khách hàng. Liên hệ với Khách hàng (hoặc các bên cần thiết khác) để thông báo cho Khách hàng về thông tin liên quan đến việc sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT.</p>
      <p>5. Quảng cáo, tiếp thị dựa trên sở thích, thói quen sử dụng dịch vụ của Khách hàng: VNPT/Công ty con của VNPT có thể sử dụng Dữ liệu cá nhân để quảng cáo, tiếp thị với Khách hàng về các sản phẩm, dịch vụ của VNPT/Công ty con của VNPT, chương trình khuyến mại, nghiên cứu, khảo sát, tin tức,
        thông
        tin cập nhật, các sự kiện, cuộc thi có thưởng, trao các phần thưởng có liên quan, các quảng cáo và nội dung có liên quan về sản phẩm, dịch vụ của VNPT/Công ty con của VNPT hoặc của các đối tác hợp tác với VNPT/Công ty con của VNPT.</p>
      <p>Trường hợp Khách hàng không muốn nhận email, tin nhắn và/hoặc bản tin định kỳ với mục đích quảng cáo, tiếp thị của VNPT/Công ty con của VNPT với tần suất tùy thuộc vào Chính sách của VNPT/Công ty con của VNPT theo từng thời kỳ và phù hợp với quy định pháp luật, Khách hàng có thể từ chối
        theo cách
        thức đã được VNPT/Công ty con của VNPT hướng dẫn trên các kênh, phương tiện như tin nhắn SMS, cuộc gọi, dấu tích trên website/wapsite/ứng dụng v.v. hoặc liên hệ với tổng đài chăm sóc Khách hàng của VNPT/Công ty con của VNPT;</p>
      <p>6. Lập các báo cáo tài chính, báo cáo hoạt động hoặc các loại báo cáo liên quan khác mà pháp luật quy định;</p>
      <p>7. Tuân thủ các nghĩa vụ pháp lý theo quy định của pháp luật;</p>
      <p>8. Ngăn chặn gian lận hoặc giảm thiểu mối đe doạ đối với tính mạng, sức khỏe của người khác và lợi ích công cộng: VNPT/Công ty con của VNPT có thể sử dụng thông tin cá nhân của Khách hàng để ngăn chặn và phát hiện gian lận, lạm dụng nhằm bảo vệ Khách hàng, VNPT/Công ty con của VNPT và các
        chủ thể
        liên quan;</p>
      <p>9. Quản trị nội bộ;</p>
      <p>10. Các mục đích khác có liên quan đến những mục đích được nêu trên.</p>
      <p><b>Điều 4. Cách thức xử lý Dữ liệu cá nhân</b></p>
      <p>VNPT/Công ty con của VNPT áp dụng một hoặc nhiều hoạt động tác động tới Dữ liệu cá nhân như: thu thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa, hủy Dữ liệu cá
        nhân
        hoặc các hành động khác có liên quan.</p>
      <p><b>Điều 5. Thời gian bắt đầu, thời gian kết thúc xử lý dữ liệu</b></p>
      <p>1. Thời gian bắt đầu xử lý dữ liệu</p>
      <p>Kể từ thời điểm phát sinh các Mục Đích quy định tại Điều 3 Chính sách này.</p>
      <p>2. Thời gian kết thúc xử lý dữ liệu</p>
      <p>VNPT/Công ty con của VNPT chấm dứt việc xử lý Dữ liệu cá nhân khi đã hoàn thành Mục Đích quy định tại Chính sách này, trừ trường hợp pháp luật có quy định khác hoặc Khách hàng rút lại sự đồng ý việc xử lý Dữ liệu cá nhân hoặc khi cơ quan nhà nước có thẩm quyền yêu cầu bằng văn bản.</p>
      <p><b>Điều 6. Chia sẻ Dữ liệu cá nhân</b></p>
      <p>Bằng việc chấp thuận Chính sách này, Khách hàng hiểu và đồng ý rằng VNPT/Công ty con của VNPT có thể chia sẻ Dữ liệu cá nhân của Khách hàng cho các tổ chức, cá nhân dưới đây để thực hiện các Mục Đích quy định tại Chính sách, cụ thể:</p>
      <p>1. VNPT, Công ty con của VNPT, công ty liên kết của VNPT, công ty liên kết của Công ty con của VNPT;</p>
      <p>2. Bên thứ ba cung cấp dịch vụ hoặc các đối tác trong các hợp đồng hợp tác kinh doanh (có phân chia lợi nhuận hoặc không phân chia lợi nhuận): VNPT/Công ty con của VNPT sử dụng và/hoặc hợp tác với các công ty và cá nhân khác để thực hiện một số công việc và chương trình như chương trình
        quảng
        cáo, khuyến mại dành cho Khách hàng, nghiên cứu thị trường, phân tích và phát triển sản phẩm, tư vấn chiến lược, cung cấp dịch vụ thu cước v.v. Các Bên thứ ba cung cấp dịch vụ và/hoặc các đối tác này có quyền truy cập, thu thập, sử dụng và xử lý Dữ liệu cá nhân của Khách hàng trong phạm vi
        VNPT/Công ty con của VNPT cho phép để thực hiện các chức năng của họ và phải tuân thủ quy định của pháp luật về bảo vệ Dữ liệu cá nhân với tư cách là Bên Xử lý Dữ Liệu;</p>
      <p>3. Tái cấu trúc doanh nghiệp: Trong quá trình phát triển kinh doanh, VNPT/Công ty con của VNPT có thể bán hoặc mua các doanh nghiệp hoặc tái cấu trúc doanh nghiệp phù hợp với quy định của pháp luật và nhu cầu sản xuất kinh doanh. Trong các giao dịch như vậy, Dữ liệu cá nhân sẽ được chuyển
        nhượng
        và bên nhận chuyển nhượng vẫn phải tuân theo các quy định của Chính sách này;</p>
      <p>4. VNPT/Công ty con của VNPT được phép tiết lộ Dữ liệu cá nhân theo yêu cầu của pháp luật, yêu cầu của cơ quan quản lý nhà nước có thẩm quyền;</p>
      <p>5. VNPT/Công ty con của VNPT được phép tiết lộ Dữ liệu cá nhân cho các doanh nghiệp viễn thông khác để phục vụ cho việc tính giá cước, lập hoá đơn và ngăn chặn hành vi trốn tránh thực hiện nghĩa vụ theo hợp đồng của Khách hàng.</p>
      <p><b>Điều 7. Quyền của Khách hàng</b></p>
      <p>1. Quyền được biết và Quyền đồng ý</p>
      <p>Bằng Chính sách này, VNPT/Công ty con của VNPT thông báo cho Khách hàng được biết về hoạt động xử lý Dữ liệu cá nhân trước khi thực hiện xử lý Dữ liệu cá nhân. Đồng thời, Khách hàng có quyền đồng ý hoặc không đồng ý với các điều khoản và điều kiện của Chính sách này theo cách thức đã được
        VNPT/Công ty con của VNPT hướng dẫn trên các kênh, phương tiện như tin nhắn SMS, cuộc gọi, dấu tích trên website/wapsite/ứng dụng v.v. hoặc liên hệ với tổng đài chăm sóc khách hàng của VNPT/Công ty con của VNPT;</p>
      <p>2. Quyền truy cập và yêu cầu cung cấp Dữ liệu cá nhân</p>
      <p>Khách hàng có quyền truy cập vào các ứng dụng/website/wapsite của VNPT/Công ty con của VNPT và/hoặc liên hệ trực tiếp với VNPT/Công ty con của VNPT để xem, trích xuất các Dữ liệu cá nhân mà Khách hàng đã cung cấp cho VNPT/Công ty con của VNPT phục vụ các Mục Đích quy định tại Chính sách
        này.</p>
      <p>Trường hợp Khách hàng không thể tự truy cập, trích xuất hoặc gặp khó khăn trong việc truy cập, trích xuất các Dữ liệu cá nhân, Khách hàng có thể liên hệ với VNPT/Công ty con của VNPT để được hỗ trợ.</p>
      <p>3. Quyền chỉnh sửa</p>
      <p>Khách hàng có quyền chỉnh sửa các Dữ liệu cá nhân của mình với điều kiện việc chỉnh sửa này không vi phạm các quy định của pháp luật. Trường hợp Khách hàng không thể tự chỉnh sửa hoặc gặp khó khăn trong việc chỉnh sửa các Dữ liệu cá nhân, Khách hàng có thể liên hệ với VNPT/Công ty con của
        VNPT để
        được hỗ trợ.</p>
      <p>4. Quyền phản đối, hạn chế, rút lại sự đồng ý xử lý dữ liệu</p>
      <ul>
        <p>a) Khách hàng có quyền phản đối, hạn chế hoặc rút lại sự đồng ý xử lý Dữ liệu cá nhân của Khách hàng. Tuy nhiên, việc phản đối, hạn chế hoặc rút lại sự đồng ý xử lý Dữ liệu cá nhân của Khách hàng trong một số trường hợp có thể dẫn tới việc VNPT/Công ty con của VNPT không thể cung cấp Sản
          phẩm,
          dịch vụ cho Khách hàng, điều này đồng nghĩa với việc VNPT/Công ty con của VNPT có thể đơn phương chấm dứt hợp đồng mà không cần phải bồi thường cho Khách hàng do các điều kiện để thực hiện hợp đồng đã thay đổi. Do đó, VNPT/Công ty con của VNPT khuyến nghị Khách hàng cân nhắc kĩ lưỡng trước
          khi
          phản đối, hạn chế hoặc rút lại sự đồng ý xử lý Dữ liệu cá nhân của Khách hàng</p>
        <p>b) Trường hợp Khách hàng muốn hạn chế nhận nội dung tiếp thị quảng cáo, khuyến mại từ VNPT/Công ty con của VNPT và muốn rút lại sự chấp thuận trước đó (nếu có) và/hoặc phản đối việc tiếp tục sử dụng thông tin cá nhân của mình cho mục đích quy định tại khoản 5, Điều 3 Chính sách này, Khách
          hàng
          vui lòng thực hiện theo hướng dẫn của VNPT/Công ty con của VNPT tại thời điểm VNPT/Công ty con của VNPT thu thập Dữ liệu cá nhân hoặc liên hệ với VNPT/Công ty con của VNPT theo các thông tin được cung cấp tại Chính sách này. Nếu Khách hàng không muốn nhận thông báo từ ứng dụng của
          VNPT/Công
          ty
          con của VNPT, vui lòng điều chỉnh cài đặt thông báo trong ứng dụng hoặc thiết bị của mình.</p>
      </ul>
      <p>5. Quyền xóa Dữ liệu cá nhân</p>
      <p>Khách hàng có quyền yêu cầu VNPT/Công ty con của VNPT thực hiện xóa Dữ liệu cá nhân của Khách hàng với điều kiện là yêu cầu của Khách hàng phải phù hợp với quy định của pháp luật. Tuy nhiên, yêu cầu xóa Dữ liệu cá nhân của Khách hàng trong một số trường hợp có thể dẫn tới việc VNPT/Công ty
        con
        của VNPT không thể cung cấp Sản phẩm, dịch vụ cho Khách hàng, điều này đồng nghĩa với việc VNPT/Công ty con của VNPT có thể đơn phương chấm dứt hợp đồng mà không cần phải bồi thường cho Khách hàng do các điều kiện để thực hiện hợp đồng đã thay đổi. Do đó, VNPT/Công ty con của VNPT khuyến
        nghị
        Khách hàng cân nhắc kĩ lưỡng trước khi yêu cầu VNPT/Công ty con của VNPT thực hiện xóa Dữ liệu cá nhân.</p>
      <p>6. Quyền khiếu nại, tố cáo, khởi kiện</p>
      <p>Khách hàng có quyền khiếu nại, tố cáo hoặc khởi kiện theo quy định của pháp luật</p>
      <p>7. Quyền yêu cầu bồi thường thiệt hại</p>
      <p>Khách hàng có quyền yêu cầu VNPT/Công ty con của VNPT bồi thường thiệt hại theo quy định của pháp luật khi xảy ra vi phạm quy định về bảo vệ Dữ liệu cá nhân khi thỏa mãn đồng thời các điều kiện sau:</p>
      <p>- Có hành vi vi phạm quy định pháp luật về bảo vệ Dữ liệu cá nhân của VNPT/Công ty con của VNPT;</p>
      <p>- Hành vi vi phạm nêu trên dẫn tới thiệt hại thực tế phát sinh cho Khách hàng;</p>
      <p>- Khách hàng đã thực hiện đầy đủ các nghĩa vụ về bảo vệ Dữ liệu cá nhân của mình theo quy định của pháp luật, Chính sách này và theo thỏa thuận khác giữa VNPT/Công ty con của VNPT và Khách hàng.</p>
      <p>8. Quyền tự bảo vệ</p>
      <p>Khách hàng có quyền tự bảo vệ theo quy định của Bộ luật Dân sự, luật khác có liên quan và Nghị định 13/2023/NĐ-CP về bảo vệ Dữ liệu cá nhân (và các bản sửa đổi kèm theo), hoặc yêu cầu cơ quan, tổ chức có thẩm quyền thực hiện các phương thức bảo vệ quyền dân sự theo quy định tại Điều 11 Bộ
        luật
        Dân sự.</p>
      <p><b>Điều 8. Nghĩa vụ của Khách hàng</b></p>
      <p>Khách hàng có trách nhiệm bảo vệ Dữ liệu cá nhân của mình như sau:</p>
      <p>1. Chủ động thực hiện các biện pháp bảo vệ, quản lý và sử dụng an toàn tài khoản, thiết bị di động của mình (bao gồm nhưng không giới hạn bởi điện thoại thông minh, máy tính, máy tính bảng, laptop v.v.) bằng cách đăng xuất tài khoản sau khi sử dụng, đặt một mật khẩu mạnh và khó đoán và giữ
        bí mật
        thông tin đăng nhập cũng như mật khẩu của mình. Các biện pháp bảo vệ và quản lý sử dụng an toàn tài khoản, thiết bị di động nói trên giúp ngăn chặn việc truy cập trái phép vào tài khoản của Khách hàng. VNPT được loại trừ trách nhiệm với các thiệt hại của Khách hàng trong trường hợp Khách
        hàng bị
        lộ/mất, bị đánh cắp mật khẩu, dẫn tới việc bị truy cập trái phép vào tài khoản, hoặc bất kỳ hoạt động nào trên tài khoản của Khách hàng sử dụng trên thiết bị di động bị mất, thất lạc dẫn đến người không có thẩm quyền tự ý sử dụng dịch vụ, hoặc hệ thống của VNPT bị xâm phạm bất hợp pháp bởi
        bên
        thứ ba mặc dù VNPT đã thực hiện đầy đủ các biện pháp để bảo vệ hệ thống;</p>
      <p>2. Khách hàng có trách nhiệm cập nhập các văn bản điều chỉnh Chính sách này (nếu có) trên các kênh thông tin chính thống của VNPT/Công ty con của VNPT;</p>
      <p>3. Khi đã chấp thuận toàn bộ điều khoản và điều kiện của của Chính sách này, Khách hàng có trách nhiệm cung cấp Dữ liệu cá nhân đầy đủ, chính xác theo yêu cầu của VNPT/Công ty con của VNPT và có trách nhiệm thông báo cho VNPT/Công ty con của VNPT ngay khi phát hiện hành vi vi phạm quy định
        về bảo
        vệ Dữ liệu cá nhân. Khách hàng có thể chủ động cung cấp Dữ liệu cá nhân nằm ngoài yêu cầu của VNPT/Công ty con của VNPT với điều kiện Khách hàng phải tuân thủ quy định tại khoản 2.5, Điều 2 Chính sách này;</p>
      <p>4. Khách hàng có trách nhiệm tôn trọng Dữ liệu cá nhân của chủ thể khác và thực hiện quy định của pháp luật về bảo vệ Dữ liệu cá nhân, tham gia phòng, chống các hành vi vi phạm quy định về bảo vệ Dữ liệu cá nhân.</p>
      <p><b>Điều 9. Lưu trữ Dữ liệu cá nhân</b></p>
      <p>1. Dữ liệu cá nhân của Khách hàng do VNPT/Công ty con của VNPT lưu trữ sẽ được bảo mật. VNPT/Công ty con của VNPT trong khả năng của mình, sẽ nỗ lực thực hiện các biện pháp hợp lý để bảo vệ Dữ liệu cá nhân của Khách hàng.</p>
      <p>2. Địa điểm lưu trữ Dữ liệu cá nhân</p>
      <p>Trong phạm vi pháp luật cho phép, VNPT/Công ty con của VNPT có thể lưu trữ Dữ liệu cá nhân của Khách hàng tại Việt Nam và ở nước ngoài, kể cả trên giải pháp lưu trữ điện toán đám mây. VNPT/Công ty con của VNPT áp dụng các tiêu chuẩn về bảo mật dữ liệu phù hợp với quy định pháp luật hiện
        hành.</p>
      <p>3. Thời gian lưu trữ Dữ liệu cá nhân</p>
      <p>VNPT/Công ty con của VNPT chỉ thực hiện lưu trữ Dữ liệu cá nhân của Khách hàng trong khoảng thời gian cần thiết và hợp lý để hoàn thành các Mục Đích quy định tại Chính sách này. Tuy nhiên, trường hợp pháp luật hiện hành có quy định khác về thời hạn lưu trữ Dữ liệu cá nhân, VNPT/Công ty con
        của
        VNPT có nghĩa vụ tuân thủ quy định của pháp luật.</p>
      <p><b>Điều 10. Nghĩa vụ của VNPT/Công ty con của VNPT</b></p>
      <p>1. Dữ liệu cá nhân của Khách hàng được cam kết bảo mật theo quy định của pháp luật, Chính sách bảo vệ Dữ liệu cá nhân của VNPT/Công ty con của VNPT.</p>
      <p>2. VNPT/Công ty con của VNPT nỗ lực đảm bảo Dữ liệu cá nhân của Khách hàng được bảo vệ khỏi các hành vi vi phạm quy định về bảo vệ Dữ liệu cá nhân. VNPT/Công ty con của VNPT duy trì cam kết bảo mật Dữ liệu cá nhân bằng cách áp dụng những biện pháp vật lý, điện tử và quản lý để bảo vệ Dữ
        liệu cá
        nhân, bao gồm:</p>
      <ul>
        <p>a) Các máy chủ trang thông tin điện tử chính thức của VNPT và các hệ thống thông tin chứa dữ liệu cá nhân của VNPT đều được bảo vệ bởi các biện pháp, công nghệ bảo mật như tường lửa, mã hóa, chống xâm nhập trái phép v.v.; ban hành các biện pháp kiểm soát về con người, xây dựng quy trình
          kiểm
          tra,
          đánh giá, rà soát để phòng tránh các hành vi vi phạm quy định về bảo vệ Dữ liệu cá nhân.</p>
        <p>b) VNPT sẽ thực hiện tất cả các biện pháp phù hợp để đảm bảo rằng Dữ liệu cá nhân của Khách hàng được xử lý đúng với Mục Đích đã thông báo. VNPT/Công ty con của VNPT sẽ luôn tuân thủ những yêu cầu của pháp luật liên quan đến việc lưu trữ Dữ liệu cá nhân.</p>
      </ul>
      <p>3. Thực hiện các yêu cầu của Khách hàng liên quan đến dữ liệu cá nhân của Khách hàng với điều kiện các yêu cầu của Khách hàng phải phù hợp với quy định của pháp luật.</p>
      <p>4. Các nghĩa vụ khác theo quy định của pháp luật và của Chính sách này.</p>
      <p><b>Điều 11. Hậu quả, thiệt hại không mong muốn có khả năng xảy ra</b></p>
      <p>1. VNPT/Công ty con của VNPT sử dụng nhiều biện pháp, công nghệ bảo mật thông tin khác nhau nhằm bảo vệ Dữ liệu cá nhân của Khách hàng không bị sử dụng hoặc chia sẻ ngoài ý muốn. VNPT/Công ty con của VNPT cam kết sẽ bảo mật một cách tối đa Dữ liệu cá nhân của Khách hàng. Một số hậu quả,
        thiệt hại
        không mong muốn có thể xảy ra bao gồm nhưng không giới hạn:</p>
      <ul>
        <p>a) Lỗi phần cứng, phần mềm trong quá trình xử lý Dữ liệu cá nhân gây ảnh hưởng không mong muốn (lỗi, hỏng, mất v.v.) Dữ liệu cá nhân của Khách hàng;</p>
        <p>b) Lỗ hổng bảo mật nằm ngoài khả năng kiểm soát của VNPT/Công ty con của VNPT, hệ thống bị hacker tấn công gây lộ lọt Dữ liệu cá nhân của Khách hàng;</p>
        <p>c) Khách hàng tự làm lộ lọt Dữ liệu cá nhân của Khách hàng do: bất cẩn hoặc bị lừa đảo; truy cập các website/tải các ứng dụng có chứa phần mềm độc hại; tự ý chia sẻ thông tin với người khác v.v.</p>
      </ul>
      <p>2. VNPT/Công ty con của VNPT khuyến cáo Khách hàng thực hiện nghiêm ngặt các trách nhiệm bảo vệ Dữ liệu cá nhân theo quy định tại Điều 8 Chính sách này và theo quy định của pháp luật.</p>
      <p>3. Trường hợp máy chủ lưu trữ dữ liệu bị hacker tấn công dẫn đến mất mát Dữ liệu cá nhân của Khách hàng, VNPT/Công ty con của VNPT có trách nhiệm thông báo vụ việc cho cơ quan có thẩm quyền để điều tra xử lý kịp thời và thông báo cho Khách hàng được biết.</p>
      <p><b>Điều 12. Sử dụng cookies</b></p>
      <p>1. Cookies có thể được sử dụng trên website của VNPT/Công ty con của VNPT. “Cookies” là các tệp văn bản nhỏ được lưu trên ổ cứng máy tính của Khách hàng nhằm giúp tùy biến trải nghiệm website cho người dùng. VNPT/Công ty con của VNPT sử dụng cookies để việc điều hướng trang web dễ dàng hơn
        và tạo
        điều kiện thuận lợi cho quá trình đăng nhập. Dữ liệu thống kê trên trang web của VNPT/Công ty con của VNPT có thể được xử lý bởi các bên thứ ba, và do đó, địa chỉ IP của Khách hàng khi truy cập trang web sẽ được chuyển tới bên thứ ba chỉ với mục đích báo cáo thống kê.</p>
      <p>2. Khi thu thập thông tin qua website của VNPT/Công ty con của VNPT, VNPT/Công ty con của VNPT tự động thu thập những thông tin không mang tính cá nhân, không xác định danh tính nhưng có liên quan tới việc sử dụng trang web bao gồm nhưng không giới hạn như: địa chỉ IP của máy tính cá nhân,
        địa
        chỉ IP của nhà cung cấp dịch vụ Internet, ngày giờ truy cập trang web, hệ điều hành, các mục đã truy cập trên trang web, nội dung tải về từ trang web và địa chỉ trang web đã trực tiếp dẫn Khách hàng tới trang web của VNPT/Công ty con của VNPT. VNPT/Công ty con của VNPT có thể dùng những
        thông
        tin này để phục vụ cho những báo cáo thống kê về lưu lượng người truy cập trang web và hành vi sử dụng trang web.</p>
      <p>3. Trường hợp Khách hàng không muốn cho phép sử dụng cookies, Khách hàng có thể từ chối bằng cách thay đổi cài đặt riêng tư trên trình duyệt web. Việc từ chối cho phép sử dụng cookies hầu như không gây ảnh hưởng tới việc điều hướng trang web. Tuy nhiên một số chức năng của trang web có thể
        bị ảnh
        hưởng. Sau khi hoàn thành việc truy cập trang web, Khách hàng vẫn có thể xóa cookies khỏi hệ thống nếu muốn.</p>
      <p><b>Điều 13. Quảng cáo trên internet và bên thứ ba</b></p>
      <p>Các website/wapsite/ứng dụng của VNPT/Công ty con của VNPT có thể bao gồm quảng cáo của bên thứ ba và liên kết tới các website/wapsite/ứng dụng khác. Các đối tác quảng cáo bên thứ ba có thể thu thập thông tin về Khách hàng khi Khách hàng tương tác với nội dung, quảng cáo hoặc dịch vụ của
        họ. Mọi
        quyền truy cập và sử dụng các liên kết hoặc trang website của bên thứ ba không bị điều chỉnh bởi Chính sách này, mà thay vào đó được điều chỉnh bởi Chính sách quyền riêng tư của các bên thứ ba đó. VNPT/Công ty con của VNPT không chịu trách nhiệm về các quy định về thông tin của các bên thứ
        ba.</p>
      <p><b>Điều 14. Xử lý Dữ liệu cá nhân không cần sự đồng ý của chủ thể dữ liệu</b></p>
      <p>VNPT/Công ty con của VNPT có thể tiến hành xử lý Dữ liệu cá nhân mà không cần sự đồng ý của chủ thể dữ liệu trong các trường hợp sau:</p>
      <p>1. Trong trường hợp khẩn cấp, cần xử lý ngay Dữ liệu cá nhân có liên quan để bảo vệ tính mạng, sức khỏe của chủ thể dữ liệu hoặc người khác;</p>
      <p>2. Việc công khai Dữ liệu cá nhân theo quy định của luật;</p>
      <p>3. Việc xử lý dữ liệu của cơ quan nhà nước có thẩm quyền trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự an toàn xã hội, thảm họa lớn, dịch bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng nhưng chưa đến mức ban bố tình trạng khẩn cấp; phòng, chống bạo
        loạn,
        khủng bố, phòng, chống tội phạm và vi phạm pháp luật theo quy định của luật;</p>
      <p>4. Để thực hiện nghĩa vụ theo hợp đồng của chủ thể dữ liệu với cơ quan, tổ chức, cá nhân có liên quan theo quy định của luật;</p>
      <p>5. Phục vụ hoạt động của cơ quan nhà nước đã được quy định theo luật chuyên ngành.</p>
      <p><b>Điều 15. Thông tin liên lạc</b></p>
      <p>Trường hợp Khách hàng có bất kỳ câu hỏi nào về Chính sách này hoặc muốn thực hiện các quyền của Khách hàng liên quan tới Dữ liệu cá nhân, vui lòng liên hệ với VNPT/Công ty con của VNPT theo các phương thức và thông tin dưới đây:</p>
      <p>1. Liên hệ tới tổng đài theo thông tin tại các website/wapsite/ứng dụng chính thức của VNPT/Công ty con của VNPT tại từng thời điểm.</p>
      <p>2. Gửi công văn tới các địa chỉ sau đây:</p>
      <ul>
        <p>a) Tổng Công ty Dịch vụ Viễn thông (VNPT VinaPhone): tại Toà nhà VinaPhone, đường Xuân Tảo, Phường Xuân La, Quận Tây Hồ, Hà Nội.</p>
        <p>b) Tổng Công ty Truyền thông (VNPT Media): tại Tòa nhà VNPT, 57 Huỳnh Thúc Kháng, Phường Láng Hạ, Quận Đống Đa, Hà Nội.</p>
        <p>c) Công ty Công nghệ thông tin VNPT (VNPT IT): tại Tòa nhà VNPT, 57 Huỳnh Thúc Kháng, Phường Láng Hạ, Quận Đống Đa, Hà Nội.</p>
      </ul>
      <p>3. Liên hệ trực tiếp tại các điểm giao dịch của VNPT/Công ty con của VNPT trên phạm vi toàn quốc.</p>
      <p>4. Các cách thức liên hệ khác như Livechat, liên hệ qua fanpage chính thức của VNPT/Công ty con của VNPT, email chăm sóc Khách hàng được cung cấp cho Khách hàng tại mọi thời điểm./</p>
    </client-only>
  </div>
</template>
<script>
export default {
  name: 'DieuKhoanSuDung'
}
</script>

<style scoped>
.content-introduce {
  padding: 15px;
}

ul {
  margin-left: 40px !important;
}
</style>
